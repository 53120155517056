import { IDropdownOption } from "@fluentui/react";
import { createSelector } from "@reduxjs/toolkit";
import { IOption } from "components/shared/dropdown";

import { IClientTable } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Client Tables.
 */
export const ClientTablesSelectors = {
	getClientTableList: (state: RootState): IClientTable[] =>
		state.clientTables.list,
	getClientTableDropdownList: createSelector(
		(state: RootState): IDropdownOption<IOption>[] =>
			state.clientTables.list?.map((tableItem: IClientTable) => ({
				text: tableItem.name,
				key: tableItem.id
			})),
		(list: IDropdownOption<IOption>[]) => list
	),
	getClientTableById: (state: RootState, id: string): null | IClientTable =>
		state.clientTables.list.find(
			(stockItem: IClientTable) => stockItem.id === id
		),
	getClientTableListLoading: (state: RootState): boolean =>
		state.clientTables.loading,
	getClientTableListQueried: (state: RootState): boolean =>
		state.clientTables.queried,
	getClientTableListQuerying: (state: RootState): boolean =>
		state.clientTables.loading,
	getIsEditing: (state: RootState): boolean => state.clientTables.editing,
	getClientTableListEditing: (state: RootState): IClientTable[] =>
		state.clientTables.editingList,
	getClientTableModalOpen: (state: RootState): boolean =>
		state.clientTables.modalOpen,
	getClientTableFormData: (state: RootState): IClientTable =>
		state.clientTables.formData,
	getNonBusyTables: (state: RootState): IClientTable[] =>
		state.clientTables.list.filter((table) => !table.busy)
	// getClientTableListError: (state: RootState) => state.clientTables.error
};
