/**
 * Stock Movement direction enum.
 */
export enum StockMovementDirection {
	in = "in",
	out = "out",
	update = "update",
	transfer = "transfer",
	destroy = "destroy"
}
