import { appStateService } from "App";
import { OpenAIModelVersion } from "../OpenAIService";
import { TrainingData, TrainingDataModels } from "../training";

/**
 * Used to type the input of the image understanding models.
 */
declare type FileInput = { file: File | Blob; propMap: string };

/**
 * Interface for a Internal pre-trained and refined model unit.
 */
interface IAIModelUnit<T> {
	name: string;
	id: string;
	queryBase: (inputs: T) => string;
	executor: <OutputType>(
		inputs: T,
		onComplete?: undefined | ((completion: OutputType | T) => Promise<void>)
	) => Promise<OutputType | T>;
}

/**
 * Interface for the BeeAI Internal pre-trained and refined models structure definition with model names within.
 */
interface IAIInternalModelStructure {
	brandDescription: string;
	categoryDescription: string;
	productDescription: string;
	productImageInterpreter: string;
	productAverageMarketPrice: string;
}

/**
 * Main contract for the BeeAI Internal pre-trained and refined models.
 */
interface IAIModels {
	brandDescription: IAIModelUnit<string>;
	categoryDescription: IAIModelUnit<string>;
	productDescription: IAIModelUnit<string>;
	productImageInterpreter: IAIModelUnit<FileInput>;
	productAverageMarketPrice: IAIModelUnit<string>;
}

/**
 * BeeAI Internal pre-trained and refined models names.
 */
const AIInternalPreTrainedDataModelNames: IAIInternalModelStructure = {
	productAverageMarketPrice: "product-average-market-price",
	brandDescription: "brand-description",
	categoryDescription: "category-description",
	productImageInterpreter: "product-image-interpreter",
	productDescription: "product-description"
};

/**
 * BeeAI Internal pre-trained and refined models.
 */
const AIModels: IAIModels = {
	/**
	 * Model that interprets an image and returns
	 * a JSON with the product metadata.
	 */
	productImageInterpreter: {
		name: AIInternalPreTrainedDataModelNames.productImageInterpreter,
		id: "beeai_7Yv6y7f",
		queryBase: (inputs: FileInput) => {
			const imageData =
				typeof inputs.file === "string"
					? inputs.file
					: inputs.file.text();

			return (
				`Você poderia me descrever brevemente a imagem "${imageData}"? ` +
				`Vou armazenar em minha base de dados. Envie somente um JSON no formato "${inputs.propMap}".`
			);
		},
		executor: async <ImageObjectOutputType>(
			inputs: FileInput
			// onComplete: (completion) => Promise<string>
		) => {
			const query = AIModels.productImageInterpreter.queryBase(inputs);
			const fileSize = inputs.file.size;

			if (!inputs || fileSize === 0)
				throw new Error(
					"Invalid image length, or the file is corrupted."
				);

			const imageData = await appStateService.company.aiChat
				.get()
				.sendImage(
					query,
					inputs.file,
					AIInternalPreTrainedDataModelNames.productImageInterpreter
				);

			return JSON.parse(imageData) as ImageObjectOutputType;
		}
	},
	/**
	 * Model which will interpret a Brand name
	 * and return a simplified description for it.
	 */
	brandDescription: {
		name: AIInternalPreTrainedDataModelNames.brandDescription,
		id: "beeai_dVg6jz4f",
		queryBase: (inputs: string) =>
			`Você poderia me descrever brevemente a marca "${inputs}"? ` +
			`Vou armazenar em minha base de dados. Envie somente a descrição.`,
		executor: async (
			inputs: string
			// onComplete: (completion) => Promise<string>
		) => {
			const query = AIModels.brandDescription.queryBase(inputs);

			if (!inputs || inputs.length === 0)
				throw new Error("Invalid brand name.");

			return await appStateService.openai.text
				.get()
				.getDescription(
					query,
					AIInternalPreTrainedDataModelNames.brandDescription
				);
		}
	},
	/**
	 * Model which will interpret a Product name
	 * and return a simplified description for it.
	 */
	productDescription: {
		name: AIInternalPreTrainedDataModelNames.productDescription,
		id: "beeai_8Yv6y7f",
		queryBase: (inputs: string) =>
			`Você poderia me descrever brevemente o produto "${inputs}"? ` +
			`Vou armazenar em minha base de dados. Envie somente a descrição.`,
		executor: async (
			inputs: string
			// onComplete: (completion) => Promise<string>
		) => {
			const query = AIModels.productDescription.queryBase(inputs);

			if (!inputs || inputs.length === 0)
				throw new Error("Invalid product name.");

			return await appStateService.openai.text
				.get()
				.getDescription(
					query,
					AIInternalPreTrainedDataModelNames.productDescription
				);
		}
	},
	/**
	 * Model which will interpret a Category name
	 * and return a simplified description for it.
	 */
	categoryDescription: {
		name: AIInternalPreTrainedDataModelNames.categoryDescription,
		id: "beeai_3H1s2d4f",
		queryBase: (inputs: string) =>
			`Você poderia me descrever brevemente uma categoria de produtos que se chame "${inputs}"? ` +
			`Vou armazenar em minha base de dados. Envie somente a descrição.`,
		executor: async (
			inputs: string
			// onComplete: (completion) => Promise<string>
		) => {
			const query = AIModels.categoryDescription.queryBase(inputs);

			if (!inputs || inputs.length === 0)
				throw new Error("Invalid category name.");

			return await appStateService.openai.text
				.get()
				.getDescription(
					query,
					AIInternalPreTrainedDataModelNames.categoryDescription
				);
		}
	},
	/**
	 * Model which will interpret a Product name
	 * and return the average price for it.
	 */
	productAverageMarketPrice: {
		name: AIInternalPreTrainedDataModelNames.productAverageMarketPrice,
		id: "beeai_9Yv6y7f",
		queryBase: (inputs: string) =>
			`Você poderia me informar o preço médio do produto "${inputs}"? ` +
			`Vou armazenar em minha base de dados. ` +
			`Envie somente o range de preços, ` +
			`por exemplo, "R$ 110,00 - R$ 224,00". ` +
			`Pesquise no Mercado Livre e me envie o valor médio para o Brasil."`,
		executor: async (
			inputs: string
			// onComplete: (completion) => Promise<string>
		) => {
			const query = AIModels.productAverageMarketPrice.queryBase(inputs);
			let averagePrice: null | string = null;

			if (!inputs || inputs.length === 0)
				throw new Error("Invalid product name.");

			// Finds the average price of the product in the market, using GPT-4.
			averagePrice = await appStateService.openai.text
				.get()
				.addPresetMessages(
					TrainingData[TrainingDataModels.brandDescription],
					true
				)
				.getCompletion(
					query,
					AIInternalPreTrainedDataModelNames.productAverageMarketPrice,
					async (aiPrice) => {
						if (!aiPrice) return null;

						const validPrice = aiPrice.match(/\d+/g);

						if (!validPrice || validPrice.length === 0) return null;

						averagePrice = validPrice[0];
					},
					OpenAIModelVersion.GPT4_0613
				);

			return averagePrice;
		}
	}
};

export {
	type FileInput,
	IAIModels,
	IAIModelUnit,
	IAIInternalModelStructure,
	AIModels,
	AIInternalPreTrainedDataModelNames
};
