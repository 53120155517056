import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxSlices } from "redux/slices";
import {
	productsInitialState as initialState,
	IProduct
} from "models/product/Product";
import { ISetEditingAction } from "interfaces/actions";

/**
 * Products Redux slice.
 */
export const productsSlice = createSlice({
	name: ReduxSlices.Products,
	initialState,
	reducers: {
		setList: (state, action: PayloadAction<IProduct[]>) => {
			state.list = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setQueried: (state, action: PayloadAction<boolean>) => {
			state.queried = action.payload;
			state.loading = false;
		},
		setEditing: (
			state,
			action: PayloadAction<ISetEditingAction<IProduct>>
		) => {
			state.editing = action.payload.editing;
			state.editingList = action.payload.items;
		},
		setEditByID: (state, action: PayloadAction<IProduct>) => {
			const index = state.list.findIndex(
				(item) => item.id === action.payload.id
			);
			if (index >= 0) {
				const newList = [...state.list];

				newList[index] = action.payload;

				state.list = newList;
			}
		},
		setEditingList: (state, action: PayloadAction<IProduct[]>) => {
			state.editingList = action.payload;
		},
		setInStockIds: (
			state,
			action: PayloadAction<{
				productId: string;
				stockIds: string[];
			}>
		) => {
			state.inStockIds[action.payload.productId] =
				action.payload.stockIds;
			// if (action.payload.productId in state.inStockIds) {
			// } else {
			// 	state.inStockIds[action.payload.productId] =
			// 		action.payload.stockIds;
			// }
		},
		resetEditing: (state) => {
			state.editing = false;
			state.editingList = [];
		},
		reset: (state) => {
			// state = { ...initialState };
			state.list = [];
			state.queried = false;
			state.loading = false;
			state.editing = false;
			state.editingList = [];
			state.inStockIds = {};
		},
		resetInStockIds: (state) => {
			state.inStockIds = initialState.inStockIds;
		}
		// setError: (state, action: PayloadAction<string>) => {
		// 	state.error = action.payload;
		// }
	}
});

export const { reducer, actions } = productsSlice;

export default reducer;

// TODO: Selectors, split file
