/**
 * Allowed input types.
 */
export enum AllowedInputTypes {
	text = "text",
	textarea = "textarea",
	email = "email",
	file = "file",
	password = "password",
	number = "number",
	float = "float",
	tel = "tel",
	url = "url"
}
