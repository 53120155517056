import { appStateService } from "App";
import { useMessageModal } from "../message/useMessageModal";
import { MessageModalType } from "enums";

/**
 * Confirm Modal component props interface.
 */
export interface ConfirmModalHook {
	// title?: string;
	onCancel?: () => void | Promise<void>;
	text: string;
	setText?: (text: string) => void;
	onOpen: (
		newText?: undefined | string,
		confirmCallBack?: undefined | (() => void)
	) => void;
	onClear: () => void;
}

/**
 * Use Confirm Modal hook component.
 *
 * @returns ConfirmModalOptions
 */
export const useConfirmModal = (): ConfirmModalHook => {
	// TODO: Apply translate
	const texts = {
		title: "Confirmar Ação",
		content: "Executar a ação selecionada?"
	};
	const { text, setText, setOpen, setTitle, setType, cancel } =
		useMessageModal();

	const onOpen = async (
		newText?: undefined | string,
		confirmCallBack?: undefined | (() => void)
	) => {
		setTitle(texts.title);
		setType(MessageModalType.info);
		if (newText && newText.length > 0) setText(newText);
		else setText(texts.content);

		// Prepares the callback to be executed right after confirmation is activated
		if (typeof confirmCallBack === "function") {
			appStateService.appManager.setConfirmCallback(confirmCallBack);
		}

		setOpen(true);
	};

	/**
	 * Cleans-up the to the pre-configured defaults
	 */
	const onClear = () => {
		setOpen(false);
		setText("");
		setTitle("");
		setType(MessageModalType.info);
		appStateService.appManager.clearMessageCallbacks();
	};

	return { onCancel: cancel, text, setText, onOpen, onClear };
};
