import { ISliceList } from "interfaces/slices";
import { ChatInstance, IChatInstance } from "models/openai/chat";

/**
 * AIChat Redux slice interface.
 */
interface IAIChatSlice extends ISliceList<IChatInstance> {
	activeChat: null | ChatInstance;
}

/**
 * AI Model Process list initial state.
 */
const aiChatInitialState: IAIChatSlice = {
	list: [],
	editingList: [],
	queried: false,
	loading: false,
	editing: false,
	activeChat: null
};

export { IAIChatSlice, aiChatInitialState };
