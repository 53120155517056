import { Timestamp } from "firebase/firestore";
import { ISliceList } from "interfaces/slices";
import { IFirebaseItem } from "interfaces/index";
import { StockMovementDirection } from "enums";

/**
 * Stock Movement basic interface.
 */
interface IStockMovement extends IFirebaseItem {
	stockId: string;
	productId: string;
	productSku: string;
	productBarCode: string;
	quantity: number;
	direction: StockMovementDirection;
}

/**
 * Stock Redux slice interface.
 */
interface IStockMovementSlice extends ISliceList<IStockMovement> {}

/**
 * Stock Movement factory class.
 */
class StockMovement implements IStockMovement {
	id: string = "";
	stockId: string = "";
	productId: string = "";
	productSku: string = "";
	productBarCode: string = "";
	quantity: number = 0;
	direction: StockMovementDirection = StockMovementDirection.out;
	timestamp: Timestamp = Timestamp.now();
	deleted: boolean = false;

	constructor(data?: undefined | IStockMovement) {
		this.id = data?.id ?? "";
		this.stockId = data?.stockId ?? "";
		this.productId = data?.productId ?? "";
		this.productSku = data?.productSku ?? "";
		this.productBarCode = data?.productBarCode ?? "";
		this.quantity = data?.quantity ?? 0;
		this.direction = data?.direction ?? StockMovementDirection.out;
		this.timestamp = data?.timestamp ?? Timestamp.now();
		this.deleted = data?.deleted ?? false;
	}
}

/**
 * Stock Movement initial Slice state.
 */
const stockMovementInitialState: IStockMovementSlice = {
	editing: false,
	editingList: [],
	list: [],
	loading: false,
	queried: false
	// error: ""
};

export {
	IStockMovement,
	StockMovement,
	IStockMovementSlice,
	stockMovementInitialState
};
