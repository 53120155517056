import { Timestamp } from "firebase/firestore";
import { ISliceList } from "interfaces/slices";
import { IFirebaseItem } from "interfaces/index";

/**
 * ClientTable basic interface.
 */
interface IClientTable extends IFirebaseItem {
	orderId: string;
	name: string;
	busy: boolean;
	enabled: boolean;
}

/**
 * ClientTable Redux slice interface.
 */
interface IClientTableSlice extends ISliceList<IClientTable> {
	modalOpen: boolean;
	formData: IClientTable;
}

/**
 * ClientTable factory class.
 */
class ClientTable implements IClientTable {
	id: string = "";
	orderId: string = "";
	name: string = "";
	busy: boolean = false;
	enabled: boolean = true;
	timestamp: Timestamp = Timestamp.now();
	deleted: boolean = false;

	constructor(data?: undefined | IClientTable) {
		this.id = data?.id ?? "";
		this.orderId = data?.orderId ?? "";
		this.name = data?.name ?? "";
		this.busy = data?.busy ?? false;
		this.enabled = data?.enabled ?? true;
		this.timestamp = data?.timestamp ?? Timestamp.now();
		this.deleted = data?.deleted ?? false;
	}
}

/**
 * Client Table initial state.
 */
const clientTableInitialState: IClientTableSlice = {
	editing: false,
	editingList: [],
	list: [],
	loading: false,
	queried: false,
	modalOpen: false,
	formData: new ClientTable()
	// error: ""
};

export {
	IClientTable,
	ClientTable,
	IClientTableSlice,
	clientTableInitialState
};
