import React, { useMemo, useRef } from "react";
import { SidePanel } from "components/shared/panel/SidePanel";
import { appStateService } from "App";
import { useSelector } from "react-redux";
import { UserSelectors } from "redux/selectors";
import { InputText } from "components/shared/input";
// import { IconButton } from "components/shared/iconbutton";
import { EditIconButton } from "components/shared/button";
import { Button, ButtonVariant } from "components/shared/button";
import { translate } from "hooks/i18n"; // Adjust the import path as needed

import "./UserProfileForm.scss";

/**
 * Contract for the properties of the UserProfileForm component.
 */
interface IUserProfileFormProps {
	/**
	 * Determines whether the modal is open or not.
	 */
	open: boolean;
}

declare type SetterFunction = (
	propName: string,
	newValue: string | number | boolean
) => void;

const FieldGroup = (props) => {
	return <div className="field-group">{props.children}</div>;
};

interface IFieldGroupValueProps {
	id: string;
	name: string;
	setter: SetterFunction;
	isEditing: boolean;
	disabled?: undefined | boolean;
}

const FieldGroupValue: React.FC<IFieldGroupValueProps> = (props) => {
	const userProfileData = useSelector(UserSelectors.selectUserProfile);
	const handleChange =
		(setter: SetterFunction) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setter(event.target.name, event.target.value);
		};
	const value = useMemo(
		() => userProfileData[props.name],
		[userProfileData, props.name]
	);

	return (
		<div>
			{props.isEditing ? (
				<InputText
					id={props.id}
					name={props.name}
					value={value}
					onChange={handleChange(props.setter)}
					disabled={props.disabled}
				/>
			) : undefined !== value && value !== "" ? (
				value
			) : (
				"-"
			)}
		</div>
	);
};

/**
 * UserProfileForm Component, which displays the user's profile information.
 * It is also editable by enabling the mode.
 *
 * @param props IUserProfileFormProps
 * @returns React.FC<IUserProfileFormProps>
 */

const UserProfileForm: React.FC<IUserProfileFormProps> = (props) => {
	const propNames = {
		userName: "name",
		userDisplayName: "displayName",
		userMail: "mail",
		isAdmin: "isAdmin"
	};
	const userProfileData = useSelector(UserSelectors.selectUserProfile);
	const originalProfileData = useRef(userProfileData);
	const isEditing = useSelector(UserSelectors.selectUserProfileFormEditing);

	const texts = useMemo(
		() => ({
			formTitle: translate("userProfileForm.formTitle"),
			sectionTitle: translate("userProfileForm.sectionTitle"),
			propTitles: {
				userName: translate("userProfileForm.propTitles.userName"),
				userDisplayName: translate(
					"userProfileForm.propTitles.userDisplayName"
				),
				userMail: translate("userProfileForm.propTitles.userMail"),
				isAdmin: translate("userProfileForm.propTitles.isAdmin")
			},
			ariaLabels: {
				editUserName: translate(
					"userProfileForm.ariaLabels.editUserName"
				),
				editDisplayName: translate(
					"userProfileForm.ariaLabels.editDisplayName"
				)
			}
		}),
		[
			/* dependencies if any, e.g., current language */
		]
	);

	const onAfterSave = (closeForm: undefined | boolean) => {
		appStateService.user.setProfileFormEditing(false);

		if (closeForm && appStateService.user.getProfileFormOpen()) {
			appStateService.user.setProfileFormOpen(false);
		}
	};

	const fetchLatestUserData = async () => {
		// Replace with actual data fetching logic, e.g., API call or selector
		const latestData = await appStateService.user.getLatestProfile();
		return latestData;
	};

	const handleSaveChanges = async (closeForm: boolean) => {
		try {
			const latestData = await fetchLatestUserData();
			const updatedData = {
				...latestData,
				name: userProfileData.name,
				displayName: userProfileData.displayName
				// mail: userProfileData.mail,
				// isAdmin: userProfileData.isAdmin
			};
			await appStateService.user.updateItem(updatedData);
			onAfterSave(closeForm);
		} catch (error) {
			// Handle error (optional)
			console.error("Failed to save changes:", error);
		}
	};

	const handleSaveAndClose = () => {
		handleSaveChanges(true);
	};

	const handleSave = () => {
		handleSaveChanges(false);
	};

	const handleEdit = () => {
		appStateService.user.setProfileFormEditing(true);
	};

	const handleCancel = () => {
		// Restores the original data to the form, if anything changed.
		appStateService.user.setName(originalProfileData.current.name);
		appStateService.user.setDisplayName(
			originalProfileData.current.displayName
		);
		// appStateService.user.setEmail(originalProfileData.current.mail);

		// Closes the form.
		appStateService.user.setProfileFormEditing(false);
		appStateService.user.setProfileFormOpen(false);
	};

	return (
		<SidePanel
			title={texts.formTitle}
			open={props.open}
			onSubmit={handleSaveAndClose}
			onCancel={handleCancel}
			isDirty={
				isEditing &&
				(userProfileData.name !== originalProfileData.current.name ||
					userProfileData.displayName !==
						originalProfileData.current.displayName)
			}
		>
			<div className="userProfileForm">
				<div className="section">
					<h3 className="section_title">{texts.sectionTitle}</h3>
					<form onSubmit={() => {}}>
						<FieldGroup>
							<label htmlFor={propNames.userName}>
								{texts.propTitles.userName}
							</label>
							<div className="field-row">
								<FieldGroupValue
									id={propNames.userName}
									name={propNames.userName}
									isEditing={isEditing}
									setter={(prop, val) =>
										appStateService.user.setName(
											val.toString()
										)
									}
								/>
								<EditIconButton
									onClick={handleEdit}
									aria-label={texts.ariaLabels.editUserName}
									disabled={isEditing}
								/>
							</div>
						</FieldGroup>
						<FieldGroup>
							<label htmlFor={propNames.userDisplayName}>
								{texts.propTitles.userDisplayName}
							</label>
							<div className="field-row">
								<FieldGroupValue
									id={propNames.userDisplayName}
									name={propNames.userDisplayName}
									isEditing={isEditing}
									setter={(prop, val) =>
										appStateService.user.setDisplayName(
											val.toString()
										)
									}
								/>
								<EditIconButton
									onClick={handleEdit}
									aria-label={
										texts.ariaLabels.editDisplayName
									}
									disabled={isEditing}
								/>
							</div>
						</FieldGroup>
						<FieldGroup>
							<label htmlFor={propNames.userMail}>
								{texts.propTitles.userMail}
							</label>
							<a href={`mailto:${userProfileData.mail}`}>
								{userProfileData.mail}
							</a>
						</FieldGroup>
						<FieldGroup>
							<label htmlFor={propNames.isAdmin}>
								{texts.propTitles.isAdmin}
							</label>
							{userProfileData?.roles?.admin ? "Sim" : "Não"}
						</FieldGroup>
					</form>
				</div>
				{/* <div className="footer">
					<Button
						text={"Cancelar"}
						onClick={handleCancel}
						variant={ButtonVariant.Secondary}
					/>
					{!isEditing ? (
						<Button text={"Editar Perfil"} onClick={handleEdit} />
					) : (
						<Button text={"Salvar"} onClick={handleSave} />
					)}
				</div> */}
			</div>
		</SidePanel>
	);
};

export { UserProfileForm, IUserProfileFormProps };
