import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISetEditingAction } from "interfaces/actions";
import { IClientTable, clientTableInitialState } from "models/index";
import { ReduxSlices } from "redux/slices";

/**
 * ClientTable Redux slice.
 */
const clientTableSlice = createSlice({
	name: ReduxSlices.ClientTables,
	initialState: clientTableInitialState,
	reducers: {
		setList: (state, action: PayloadAction<IClientTable[]>) => {
			state.list = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setQueried: (state, action: PayloadAction<boolean>) => {
			state.queried = action.payload;
			state.loading = false;
		},
		setEditing: (
			state,
			action: PayloadAction<ISetEditingAction<IClientTable>>
		) => {
			state.editing = action.payload.editing;
			state.editingList = action.payload.items;
		},
		setEditByID: (state, action: PayloadAction<IClientTable>) => {
			const index = state.list.findIndex(
				(item) => item.id === action.payload.id
			);
			if (index >= 0) {
				const newList = [...state.list];

				newList[index] = action.payload;

				state.list = newList;
			}
		},
		setEditingList: (state, action: PayloadAction<IClientTable[]>) => {
			state.editingList = action.payload;
		},
		setModalOpen: (state, action: PayloadAction<boolean>) => {
			state.modalOpen = action.payload;
		},
		setClientTableFormData: (
			state,
			action: PayloadAction<IClientTable>
		) => {
			state.formData = Object.assign(
				{ ...state.formData },
				{ ...action.payload },
				{}
			);
		},
		resetEditing: (state) => {
			state.editing = false;
			state.editingList = [];
		},
		reset: (state) => {
			state.editing = false;
			state.editingList = [];
			state.list = [];
			state.loading = false;
			state.queried = false;
		}
	}
});

export const {
	setList,
	setLoading,
	setQueried,
	setEditing,
	setEditByID,
	setEditingList,
	setModalOpen,
	setClientTableFormData,
	resetEditing,
	reset
} = clientTableSlice.actions;

export const { reducer, actions } = clientTableSlice;

export default reducer;
