/**
 * Enum for possible OrderItemStatus
 */
export enum OrderItemStatus {
	new = "new",
	pending = "pending",
	preparing = "preparing",
	ready = "ready",
	tableServed = "tableServed",
	delivered = "delivered",
	cancelled = "cancelled"
}
