import React from "react";
import { useSelector } from "react-redux";

import { AppConfigSelectors, UserSelectors } from "redux/selectors";
import { Routes } from "components/router/Routes";
import { UserProfileButton } from "components/shared/button";
import { useTranslate } from "hooks/i18n";
import { AppImageProvider } from "static/index";
import { AppBarTestIds } from "static/test";
// import { useNavigate } from "react-router-dom";
// import { useNavigatorReference } from "hooks/useNavigatorReference";

import "./shared/HeaderStyles.scss";

/**
 * Application Header component.
 * Uses a Title, Buttons, and profile data.
 */
function Header(): JSX.Element | null {
	const appTitle = useTranslate("app.bar.title");
	const isAppBarHidden = useSelector(AppConfigSelectors.getAppBarHidden);
	const isAuthenticated = useSelector(UserSelectors.selectAuthenticated);
	const userProfileName = useSelector(
		UserSelectors.selectUserProfileNameWithAnon
	);

	/**
	 * Raw navigation function.
	 * Using hash router.
	 * @param route
	 */
	function navigate(route: string) {
		window.location.hash = route;
	}

	/**
	 * Navigation function.
	 * Using hash router.
	 * @param route
	 */
	async function onNavigateHome(
		event: React.MouseEvent<HTMLButtonElement | HTMLImageElement>
	) {
		event.preventDefault();

		if (window.location.hash !== `#${Routes.Home}`) {
			navigate(Routes.Home);
		}
	}

	return !isAppBarHidden && isAuthenticated ? (
		<header className="Header">
			<div className="Header__appTitle">
				<img
					src={AppImageProvider.Header.symbol.src}
					alt={AppImageProvider.Header.symbol.alt}
					title={AppImageProvider.Header.symbol.title}
					onClick={onNavigateHome}
					data-tid={AppBarTestIds.Icon}
				/>
				<button onClick={onNavigateHome} data-tid={AppBarTestIds.Title}>
					{appTitle}
				</button>
			</div>
			<nav className="Header__navButtons">
				<span
					className="Header__navButtonUnit user-name-container"
					data-tid={AppBarTestIds.UserName}
				>
					<span className="user-name">{userProfileName}</span>
				</span>
				<span className="Header__navButtonUnit">
					{/* This can be a placeholder for other buttons */}
				</span>
				<span className="Header__navButtonUnit">
					<UserProfileButton />
				</span>
			</nav>
		</header>
	) : null;
}

export { Header };
