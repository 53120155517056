import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxSlices } from "redux/slices";
import {
	clientsInitialState as initialState,
	IClient
} from "models/client/Client";
import { ISetEditingAction } from "interfaces/actions";

/**
 * Clients Redux slice.
 */
export const clientsSlice = createSlice({
	name: ReduxSlices.Clients,
	initialState,
	reducers: {
		setList: (state, action: PayloadAction<IClient[]>) => {
			state.list = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setQueried: (state, action: PayloadAction<boolean>) => {
			state.queried = action.payload;
			state.loading = false;
		},
		setEditing: (
			state,
			action: PayloadAction<ISetEditingAction<IClient>>
		) => {
			state.editing = action.payload.editing;
			state.editingList = action.payload.items;
		},
		setEditByID: (state, action: PayloadAction<IClient>) => {
			const index = state.list.findIndex(
				(item) => item.id === action.payload.id
			);
			if (index >= 0) {
				const newList = [...state.list];

				newList[index] = action.payload;

				state.list = newList;
			}
		},
		setEditingList: (state, action: PayloadAction<IClient[]>) => {
			state.editingList = action.payload;
		},
		resetEditing: (state) => {
			state.editing = false;
			state.editingList = [];
		},
		reset: (state) => {
			state.editing = false;
			state.editingList = [];
			state.list = [];
			state.loading = false;
			state.queried = false;
		}
		// setError: (state, action: PayloadAction<string>) => {
		// 	state.error = action.payload;
		// }
	}
});

export const { reducer, actions } = clientsSlice;

export default reducer;

// TODO: Selectors, split file
