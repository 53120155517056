import React from "react";

/**
 * FormContainer props contract interface.
 */
export interface IFormContainerProps {
	children: React.ReactNode | React.ReactNode[];
	sideForm?: undefined | boolean;
	// onSubmit: () => void;
	// onCancel: () => void;
	// submitDisabled?: undefined | boolean;
}

/**
 * Standard app Form Container component
 */
export const FormContainer: React.FC<IFormContainerProps> = (props) => {
	return (
		<form
			className={`form-container ${!props?.sideForm ? "" : "side-form"}`}
		>
			{props.children}
		</form>
	);
};
