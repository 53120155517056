import { translate } from "hooks/i18n";

export interface IChatOwners {
	user: "user" | "system";
	system: "user" | "system";
}

export const chatOwners = {
	user: "user",
	system: "system"
};

export const chatOwnerNames = {
	user: translate("global.you"),
	system: "Bee AI"
};
