import { useSelector } from "react-redux";
import { AppConfigSelectors } from "redux/selectors";

import { appStateService } from "App";
import { MessageModalType } from "enums";

/**
 * Contract for the properties of the App Message Modal hook.
 */
export interface IMessageModalHook {
	open: boolean;
	type: MessageModalType;
	title: string;
	text: string;
	shouldUseCancel: boolean;
	confirm: () => void;
	cancel?: undefined | (() => void);
	show: (
		message: string,
		type: undefined | MessageModalType,
		title?: undefined | string
	) => void;
	hide: () => void;
	setOpen: (isOpen: boolean) => void;
	setTitle: (title: string) => void;
	setText: (text: string) => void;
	setType: (type: MessageModalType) => void;
}

/**
 * Hook for managing access and facilities of the App Message Modal.
 *
 * @param onConfirm The function to call when the confirm button is clicked.
 * @param onCancel [Optional] The function to call when the cancel button is clicked.
 * @returns
 */
export function useMessageModal(): IMessageModalHook {
	const open = useSelector(AppConfigSelectors.getMessageModalOpen);
	const type = useSelector(AppConfigSelectors.getMessageModalType);
	const title = useSelector(AppConfigSelectors.getMessageModalTitle);
	const text = useSelector(AppConfigSelectors.getMessageModalText);

	const setOpen = async (isOpen: boolean) => {
		appStateService.appManager.setMessageModalOpen(isOpen);
	};

	const show = async (
		message: string,
		type: undefined | MessageModalType = MessageModalType.info,
		title?: undefined | string
	) => {
		appStateService.appManager.showMessage(message, type, title);
	};

	const hide = async () => {
		appStateService.appManager.hideMessage();
	};

	const setTitle = async (title: string) => {
		appStateService.appManager.setMessageModalTitle(title);
	};

	const setText = async (text: string) => {
		appStateService.appManager.setMessageModalText(text);
	};

	const setType = async (type: MessageModalType) => {
		appStateService.appManager.setMessageModalType(type);
	};

	const handleConfirm = async () => {
		if (typeof appStateService.appManager.onConfirm === "function") {
			appStateService.appManager.onConfirm();
			// appStateService.appManagker.setConfirmCallback(null);
		}
		hide();
	};

	const handleCancel = async () => {
		if (typeof appStateService.appManager.onCancel === "function") {
			appStateService.appManager.onCancel();
			// appStateService.appManager.setCancelCallback(null);
		}
		hide();
	};

	const hasCustomConfirmOrCancel =
		appStateService.appManager.hasConfirmCallback() ||
		appStateService.appManager.hasCancelCallback();

	return {
		open,
		type,
		title,
		text,
		setOpen,
		setType,
		setTitle,
		setText,
		confirm: handleConfirm,
		cancel: handleCancel,
		shouldUseCancel: hasCustomConfirmOrCancel,
		show,
		hide
	};
}
