import React from "react";
import { IconButton } from "@fluentui/react";
import { IIconButtonProps } from "interfaces/index";

const iconProps = {
	iconName: "RemoveFromShoppingList"
};

/**
 * Re-usable Close icon button component
 *
 * @param param0
 * @returns
 */
export function RemoveShoppingItemButton({
	onClick,
	className,
	text,
	title,
	...otherProps
}: IIconButtonProps) {
	return (
		<IconButton
			iconProps={iconProps}
			onClick={onClick}
			className={`button icon ${className ?? ""}`}
			secondaryText={text}
			title={title ?? text}
			aria-label={text}
			{...otherProps}
		/>
	);
}
