import { IFirebaseItem } from "interfaces/index";
import { ReduxStoreService } from "services/redux";

const _baseStorePath = (companyId: string) => `companies/${companyId}`;

// TODO: Pull company ID from current User active company

/**
 * Class for managing the basics of the Product data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanySegment<T extends IFirebaseItem> extends ReduxStoreService<T> {
	private _companyId: string = "";
	private _baseStoragePath: string = "";

	constructor(
		companyId: string,
		subContext: string = "",
		setList: (items: T[]) => void,
		setQueried: (queried: boolean) => void,
		setQuerying?: undefined | ((querying: boolean) => void)
	) {
		super(
			!subContext || subContext === ""
				? _baseStorePath(companyId)
				: _baseStorePath(`${companyId}/${subContext}`),
			setList,
			setQueried,
			setQuerying
		);

		// Sets basic company segment info
		this._baseStoragePath =
			!subContext || subContext === ""
				? _baseStorePath(companyId)
				: _baseStorePath(`${companyId}/${subContext}`);
		this._companyId = companyId;
	}

	/**
	 * Gets the current Company ID.
	 *
	 * @returns The Company ID set in this instance.
	 */
	getCompanyId(): string {
		return this._companyId;
	}

	/**
	 * Gets the base storage path for the company segment.
	 *
	 * @returns The base storage path for the company segment.
	 */
	getBaseStoragePath(): string {
		return this._baseStoragePath;
	}
}

export { CompanySegment };
