import { Timestamp } from "firebase/firestore";
import { ICompanyLinksSlice, ILinkInfo } from "./ILinkInfo";

/**
 * Class for the LinkInfo object
 */
export class LinkInfo implements ILinkInfo {
	id: string;
	timestamp: Timestamp;
	name: string;
	url: string;
	owner: string;
	description: string;
	order: number;
	favorite: boolean;

	constructor(linkInfo?: undefined | ILinkInfo) {
		this.id = linkInfo?.id ?? "";
		this.name = linkInfo?.name ?? "";
		this.url = linkInfo?.url ?? "";
		this.owner = linkInfo?.owner ?? "";
		this.description = linkInfo?.description ?? "";
		this.order = linkInfo?.order ?? 0;
		this.favorite = linkInfo?.favorite ?? false;
		this.timestamp = linkInfo?.timestamp ?? Timestamp.now();
	}
}

/**
 * LinkInfos Redux Initial slice state.
 */
export const companyLinksInitialState: ICompanyLinksSlice = {
	list: [],
	loading: false,
	queried: false,
	editing: false,
	editingList: []
};
