import React, { useMemo, useState, useEffect } from "react";
import {
	Dropdown as FluentDropdown,
	IDropdownOption
} from "@fluentui/react/lib/Dropdown";
// import { useSelector } from "react-redux";
import { useTranslate } from "hooks/i18n";

import "./Dropdown.scss";

/**
 * Contract for the properties provided to the Option component.
 */
interface IOption {
	key: string;
	text: string;
}

/**
 * Contract for the properties provided to the Dropdown component.
 */
interface IDropdownProps {
	id?: string;
	options: IDropdownOption<IOption>[];
	selectedKey?: string | string[] | number;
	onChange?: (
		event: React.FormEvent<HTMLDivElement>,
		option?:
			| IDropdownOption<IOption>
			| IDropdownOption<IOption>[]
			| undefined,
		index?: number
	) => void;
	disabled?: boolean;
	placeholder?: string;
	multi?: undefined | boolean;
	onLoad?: () => Promise<void>;
	listName?: undefined | string;
	className?: undefined | string;
	hideEmptyOption?: undefined | boolean;
}

/**
 * Re-usable Dropdown component
 *
 * @param props
 * @returns
 */
const Dropdown: React.FC<IDropdownProps> = (props: IDropdownProps) => {
	const defaultOptionPlaceholder = useTranslate(
		"global.dropdown.defaultItem.placeholder"
	);
	const [queried, setQueried] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	// const [selectedOptions, setSelectedOptions] = useState<
	// 	IDropdownOption<IOption>[] | undefined
	// >(undefined);
	const defaultListName = props.placeholder ?? defaultOptionPlaceholder;
	const defaultItem = useMemo<IDropdownOption<IOption>>(
		() => ({
			key: "",
			text: defaultOptionPlaceholder
		}),
		[defaultOptionPlaceholder]
	);

	const selectedOptions = useMemo(() => {
		if (!props.selectedKey || !props.options) return [];

		// When the component has a single-selected item ID
		if (typeof props.selectedKey === "string") {
			const filteredOption = props.options.find(
				(i) => i && i.key === props.selectedKey
			);

			return filteredOption ? [filteredOption] : [];
		} else if (Array.isArray(props.selectedKey)) {
			// When the component has a multi-selected item ID
			const _selectedKeys = [...props.selectedKey];

			const filteredOptions = props.options.filter(
				(i) => i && _selectedKeys.includes(i.key.toString())
			);

			return filteredOptions;
		}
		return [];
	}, [props.selectedKey, props.options]);

	useEffect(() => {
		let shouldStop = false;

		async function load() {
			if (shouldStop) return;

			setLoading(true);
			if (typeof props.onLoad === "function") {
				await props.onLoad();
			}
			setQueried(true);
			setLoading(false);
		}

		// Simulate an API request to fetch dropdown options
		if (!props.onLoad || queried) return;

		load();

		return () => {
			shouldStop = true;
		};
	}, [queried, props]);

	const items = useMemo(() => {
		return props.options
			? [
					...(!props.hideEmptyOption ? [defaultItem] : []),
					...props.options.map((item: IDropdownOption<IOption>) => ({
						key: item.key,
						text: item.text
					}))
			  ].sort((a, b) => {
					// debugger;
					return a.text?.localeCompare(b.text);
			  })
			: !props.hideEmptyOption
			? [defaultItem]
			: [];
	}, [props.options, props.hideEmptyOption, defaultItem]);

	// ).catch((error) => {
	// 	console.error("Error fetching options:", error);
	// 	setLoading(false);
	// });

	const handleSelectOption = (
		event: React.FormEvent<HTMLDivElement>,
		option?:
			| IDropdownOption<IOption>
			| IDropdownOption<IOption>[]
			| undefined
	) => {
		if (!props.onChange)
			throw new Error(
				"Dropdown component must have an onChange event handler."
			);

		props.onChange?.(event, option);
		// setSelectedOptions(!Array.isArray(option) ? [option] : option);
	};

	// const selectedKey = useMemo(() => {
	// 	const _selectedOption = selectedOptions as IDropdownOption<IOption>;
	// 	const _selectedKey = _selectedOption ? _selectedOption?.key : undefined;

	// 	return _selectedKey;
	// }, [selectedOptions]);
	const selectedKeys = useMemo(() => {
		const _selectedOptions = selectedOptions as
			| IDropdownOption<IOption>[]
			| undefined;
		const _selectedKeys = !_selectedOptions
			? []
			: _selectedOptions
					.filter((i) => i !== undefined)
					.map((i) => i.key.toString());

		return _selectedKeys;
	}, [selectedOptions]);

	const dropdownId = props?.id ?? undefined;
	console.log("dropdown-id", dropdownId);
	console.log("dropdown-items", JSON.stringify(items));

	return (
		<div className="dropdown-container">
			<FluentDropdown
				aria-labelledby={props?.id ?? undefined}
				id={dropdownId}
				placeholder={props?.placeholder ?? defaultOptionPlaceholder}
				options={items}
				className={props?.className ?? undefined}
				// defaultSelectedKeys={props?.multi ? selectedKeys : undefined}
				multiSelect={props?.multi}
				selectedKeys={props?.multi ? selectedKeys : undefined}
				selectedKey={
					props?.multi
						? undefined
						: selectedKeys.length === 0
						? undefined
						: selectedKeys[0]
				}
				onChange={(event, option) => handleSelectOption(event, option)}
				panelProps={{
					headerText: props.listName ?? defaultListName
				}}
				disabled={props?.disabled}
				styles={{
					dropdownItem: {
						fontSize: "var(--font-size-sm)"
					}
				}}
			/>
		</div>
	);
};

export { Dropdown, IDropdownProps, IOption };
