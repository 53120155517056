import { store } from "App";
import { ICartProduct, IStore } from "interfaces/index";
import { ICategory, ICompany, IProduct } from "models/index";
import { actions } from "redux/reducers/store/reducer";
import { CompanySegment } from "services/companies/CompanySegment";

/**
 * Class for managing the basics of the Store data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
export class CompanyStoreService extends CompanySegment<IStore> {
	constructor(companyId: string) {
		super(
			companyId,
			"store",
			() => {},
			() => {}
		);
	}

	/**
	 * Gets the store data.
	 *
	 * @returns The store data
	 */
	async getStoreById(storeId: string): Promise<IStore | null> {
		return await this.getItemById(storeId);
	}

	/**
	 * Updates the store data.
	 *
	 * @param store The store data
	 */
	async updateStore(store: IStore): Promise<void> {
		await this.updateItem(store);
	}

	/**
	 * Sets the Store Data into the Slice.
	 */
	setStore(data: null | IStore): void {
		store.dispatch(actions.setStore(data));
	}

	/**
	 * Sets the Store Info into the Slice.
	 *
	 * @param IStore The Store Info Data
	 */
	setCompany(data: ICompany): void {
		store.dispatch(actions.setFoundCompany(data));
	}

	/**
	 * Sets the state of Loading (boolean): Company.
	 *
	 * @param loading The Loading Company Data
	 */
	setLoadingCompany(loading: boolean): void {
		store.dispatch(actions.setCompanyLoading(loading));
	}

	/**
	 * Sets the state of Loading (boolean): Products.
	 *
	 * @param loading The Loading Products Data
	 */
	setLoadingProducts(loading: boolean): void {
		store.dispatch(actions.setProductsLoading(loading));
	}

	/**
	 * Sets the state of Loading (boolean): Categories.
	 *
	 * @param loading The Loading Categories Data
	 */
	setLoadingCategories(loading: boolean): void {
		store.dispatch(actions.setCategoriesLoading(loading));
	}

	/**
	 * Sets the state of Loaded (boolean): Products.
	 *
	 * @param loaded The Loaded Products Data
	 */
	setLoadedProducts(loaded: boolean): void {
		store.dispatch(actions.setProductsLoaded(loaded));
	}

	/**
	 * Sets the state of Loaded (boolean): Categories.
	 *
	 * @param loaded The Loaded Categories Data
	 */
	setLoadedCategories(loaded: boolean): void {
		store.dispatch(actions.setCategoriesLoaded(loaded));
	}

	/**
	 * Sets the Selected Category.
	 *
	 * @param category The Category Info Data
	 * @returns The Store Info Data
	 */
	setSelectedCategory(category: ICategory | null): void {
		store.dispatch(actions.setSelectedCategory(category));
	}

	/**
	 * Sets the Selected Product.
	 *
	 * @param product The Product Info Data
	 * @returns The Store Info Data
	 */
	setSelectedProduct(product: IProduct | null): void {
		store.dispatch(actions.setSelectedProduct(product));
	}

	/**
	 * Sets the Categories.
	 *
	 * @param categories The Categories Data
	 */
	setCategories(categories: ICategory[]): void {
		store.dispatch(actions.setCategories(categories));
	}

	/**
	 * Sets the Products.
	 *
	 * @param products The Products Data
	 */
	setProducts(products: IProduct[]): void {
		store.dispatch(actions.setProducts(products));
	}

	/**
	 * Sets the Product Images.
	 *
	 * @param images The Product Images Data
	 */
	setProductImages(images: { productId: string; imagePath: string }[]): void {
		store.dispatch(actions.setProductImages(images));
	}

	/**
	 * Sets the Expanded categories list
	 *
	 * @param expandedCategories The Expanded Categories Data
	 * @returns The Store Info Data
	 */
	setExpandedCategories(expandedCategories: string[]): void {
		store.dispatch(actions.setExpandedCategories(expandedCategories));
	}

	/**
	 * Sets the Cart Open.
	 *
	 * @param open The Cart Open Data
	 */
	setCartOpen(open: boolean): void {
		store.dispatch(actions.setCartOpen(open));
	}

	/**
	 * Sets the Cart Items.
	 *
	 * @param cartItems The Cart Items Data
	 */
	setCartItems(cartItems: ICartProduct[]): void {
		store.dispatch(actions.setCartItems(cartItems));
	}

	/**
	 * Sets the Cart Total.
	 *
	 * @param total The Cart
	 */
	setCartTotal(total: number): void {
		store.dispatch(actions.setCartTotal(total));
	}

	/**
	 * Resets the Product Images.
	 */
	resetProductImages(): void {
		store.dispatch(actions.resetProductImages());
	}

	/**
	 * Resets the Products list.
	 */
	resetProducts(): void {
		store.dispatch(actions.resetProducts());
	}

	/**
	 * Resets the Categories list.
	 */
	resetCategories(): void {
		store.dispatch(actions.resetCategories());
	}

	/**
	 * Resets the Cart.
	 */
	resetCart(): void {
		store.dispatch(actions.resetCart());
	}

	/**
	 * Gets the Store Info from the Slice.
	 *
	 * @returns The Store Info Data
	 */
	getCompany(): ICompany | null {
		return store.getState().store.company;
	}
}
