import { useCallback, useEffect } from "react";

/**
 * React hook for handling keydown events.
 *
 * @param key - The key to listen for.
 * @param callback - The callback to be executed when the key is pressed.
 * @param target - [Optional] The target element to listen for the keydown event. Defaults to Window
 */
export function useKeyDown(
	key: string,
	callback: (e?: KeyboardEvent | MouseEvent | any) => void,
	target: HTMLElement | Window | Document = window
) {
	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === key) {
				event.preventDefault();

				callback(event);
			} else {
				return;
			}
		},
		[key, callback]
	);

	useEffect(() => {
		if (target) target.addEventListener("keydown", handleKeyDown);
		return () => {
			// on unmount
			if (target) target.removeEventListener("keydown", handleKeyDown);
		};
	}, [target, handleKeyDown]);
}
