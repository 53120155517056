import { Timestamp } from "firebase/firestore";
import { IStore, IStoreSlice } from "interfaces/index";

/**
 * User Store class
 */
export class Store implements IStore {
	id: string;
	name: string;
	owner: string;
	createdOn: string;
	updatedOn: string;
	timestamp: Timestamp;
	deleted: boolean;

	constructor(data?: undefined | IStore) {
		this.id = data?.id ?? "";
		this.name = data?.name ?? "";
		this.owner = data?.owner ?? "";
		this.createdOn = Timestamp.now().toDate().toString();
		this.updatedOn = data?.updatedOn ?? "";
		this.timestamp = Timestamp.now();
		this.deleted = false;
	}
}

/**
 * Store Redux slice initial state
 */
export const storeInitialState: IStoreSlice = {
	company: null,
	info: null,
	loading: {
		company: false,
		products: false,
		categories: false
	},
	loaded: {
		company: false,
		products: false,
		categories: false
	},
	displayCount: {
		products: 20,
		categories: 10
	},
	cart: {
		open: false,
		items: [],
		total: 0
	},
	products: [],
	categories: [],
	productImages: [],
	selectedCategory: null,
	selectedProduct: null,
	expandedCategories: []
};
