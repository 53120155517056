import React from "react";
import { Provider } from "react-redux";
// import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import store, { appPersistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";

import { AppLayout } from "AppLayout";
import { StateService } from "services/StateService";
import { ErrorBoundary } from "components/shared/error";

const appStateService = new StateService(store);

/**
 * Application main (entry) point.
 * Mode: Using Redux
 */
function App(): JSX.Element {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={appPersistor}>
				<ErrorBoundary componentName="AppLayout">
					<HashRouter
					// future={{
					// 	v7_relativeSplatPath: true
					// }}
					>
						<AppLayout />
					</HashRouter>
				</ErrorBoundary>
			</PersistGate>
		</Provider>
	);
}

export { store, appStateService };

export default App;
