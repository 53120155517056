import React from "react";
import { Icon } from "./Icon";
import { IIconDerivateProps } from "./shared/interfaces";

/**
 * Fluent UI's Triangle Up icon component.
 *
 * @param props The properties of the component.
 * @returns JSX.Element
 */
export const TriangleUpIcon: React.FC<IIconDerivateProps> = (props) => {
	return <Icon name="TriangleSolidUp12" {...props} />;
};
