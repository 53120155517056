import { ICartProduct, IStore, IStoreSlice } from "interfaces";
import { ICategory, IProduct } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Store.
 */
export const StoreSelectors = {
	getStore: (state: RootState): IStoreSlice => state.store,
	getStoreInfo: (state: RootState): IStore => state.store.info,
	getCompanyLoading: (state: RootState): boolean =>
		state.store.loading.company,
	getProductsLoading: (state: RootState): boolean =>
		state.store.loading.products,
	getCategoriesLoading: (state: RootState): boolean =>
		state.store.loading.categories,
	getProductsLoaded: (state: RootState): boolean =>
		state.store.loaded.products,
	getCategoriesLoaded: (state: RootState): boolean =>
		state.store.loaded.categories,
	getProducts: (state: RootState): IProduct[] => state.store.products,
	getCategories: (state: RootState): ICategory[] => state.store.categories,
	getProductImages: (
		state: RootState
	): { productId: string; imagePath: string }[] => state.store.productImages,
	getSelectedCategory: (state: RootState): null | ICategory =>
		state.store.selectedCategory,
	getSelectedProduct: (state: RootState): null | IProduct =>
		state.store.selectedProduct,
	getProductsDisplayCount: (state: RootState): number =>
		state.store.displayCount.products,
	getCategoriesDisplayCount: (state: RootState): number =>
		state.store.displayCount.categories,
	getExpandedCategories: (state: RootState): string[] =>
		state.store.expandedCategories,
	getCartOpen: (state: RootState): boolean => state.store.cart.open,
	getCartItems: (state: RootState): ICartProduct[] => state.store.cart.items,
	getCartTotal: (state: RootState): number => state.store.cart.total
};
