import { Timestamp } from "firebase/firestore";
import { ISliceList } from "interfaces/slices";
import { IFirebaseItem } from "interfaces/index";

/**
 * Stock basic interface.
 */
interface IStock extends IFirebaseItem {
	productId: string;
	productSku: string;
	productBarCode: string;
	supplierId: string;
	quantity: number;
	transportCost: number;
	deliveredOn: string | Timestamp;
	deliveredBy: string;
}

/**
 * Stock Redux slice interface.
 */
interface IStockSlice extends ISliceList<IStock> {
	modalOpen: boolean;
	formData: IStock;
}

/**
 * Stock factory class.
 */
class Stock implements IStock {
	id: string = "";
	productId: string = "";
	productSku: string = "";
	productBarCode: string = "";
	supplierId: string = "";
	quantity: number = 0;
	transportCost: number = 0;
	deliveredOn: string | Timestamp = "";
	deliveredBy: string = "";
	timestamp: Timestamp = Timestamp.now();
	deleted: boolean = false;

	constructor(data?: undefined | IStock) {
		this.id = data?.id ?? "";
		this.productId = data?.productId ?? "";
		this.productSku = data?.productSku ?? "";
		this.productBarCode = data?.productBarCode ?? "";
		this.supplierId = data?.supplierId ?? "";
		this.quantity = data?.quantity ?? 0;
		this.transportCost = data?.transportCost ?? 0;
		this.deliveredOn = data?.deliveredOn ?? "";
		this.deliveredBy = data?.deliveredBy ?? "";
		this.timestamp = data?.timestamp ?? Timestamp.now();
		this.deleted = data?.deleted ?? false;
	}
}

/**
 * User profile initial state.
 */
const stockInitialState: IStockSlice = {
	editing: false,
	editingList: [],
	list: [],
	loading: false,
	queried: false,
	modalOpen: false,
	formData: new Stock()
	// error: ""
};

export { IStock, Stock, IStockSlice, stockInitialState };
