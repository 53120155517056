import { PaymentTypes } from "models/payment";
import { IOrderItem } from "../interfaces/IOrderItem";
import { IOrderPayment } from "../interfaces/IOrderPayment";
import { IClientOrder } from "models";
import { Timestamp } from "firebase/firestore";
import { ClientOrderStatus, OrderDeliveryType } from "enums";

/**
 * Client Order factory class.
 */
export class ClientOrder implements IClientOrder {
	id: string = "";
	status: ClientOrderStatus = ClientOrderStatus.new;
	errorMessage: string = "";
	addingNewClient: boolean = false;
	observations: string = "";

	name: string = "";
	email: string = "";
	phone: string = "";
	documentId: string = "";
	clientId: string = "";

	address: string = "";
	number: string = "";
	borough: string = "";
	city: string = "";
	state: string = "";
	postCode: string = "";
	country: string = "";

	timestamp: Timestamp;
	lastChangedBy?: undefined | string = "";
	deleted: boolean;

	// Payment info
	payment: IOrderPayment = {
		paymentType: PaymentTypes.Pix,
		pixQRCode: "",
		cardName: "",
		cardNumber: "",
		expirationDate: "",
		cvv: "",
		billingZip: "",
		value: 0,
		checkoutId: ""
	};

	// Order items
	items: IOrderItem[] = [];

	// Management
	eventName?: undefined | string;
	deliveryAddress?: undefined | string;
	deliveryNumber?: undefined | string;
	deliveryBorough?: undefined | string;
	deliveryCity?: undefined | string;
	deliveryState?: undefined | string;
	deliveryPostCode?: undefined | string;
	deliveryCountry?: undefined | string;
	deliveryDate: Timestamp;
	deliveryType?: undefined | OrderDeliveryType;

	// Eat Inside - Table info
	tableId?: undefined | string;

	constructor(data?: undefined | IClientOrder) {
		this.id = data?.id ?? "";
		this.status = data?.status ?? ClientOrderStatus.new;
		this.errorMessage = data?.errorMessage ?? "";
		this.addingNewClient = data?.addingNewClient ?? false;
		this.observations = data?.observations ?? "";

		this.name = data?.name ?? "";
		this.email = data?.email ?? "";
		this.phone = data?.phone ?? "";
		this.documentId = data?.documentId ?? "";
		this.clientId = data?.clientId ?? "";

		this.address = data?.address ?? "";
		this.number = data?.number ?? "";
		this.borough = data?.borough ?? "";
		this.city = data?.city ?? "";
		this.state = data?.state ?? "";
		this.postCode = data?.postCode ?? "";
		this.country = data?.country ?? "";

		this.payment = data?.payment ?? {
			paymentType: PaymentTypes.Pix,
			pixQRCode: "",
			cardName: "",
			cardNumber: "",
			expirationDate: "",
			cvv: "",
			billingZip: "",
			value: 0,
			checkoutId: ""
		};
		this.items = data?.items ?? [];

		this.timestamp = data?.timestamp ?? Timestamp.now();
		this.deleted = data?.deleted ?? false;
		this.lastChangedBy = data?.lastChangedBy ?? "";

		this.eventName = data?.eventName ?? "";
		this.deliveryAddress = data?.deliveryAddress ?? "";
		this.deliveryNumber = data?.deliveryNumber ?? "";
		this.deliveryBorough = data?.deliveryBorough ?? "";
		this.deliveryCity = data?.deliveryCity ?? "";
		this.deliveryState = data?.deliveryState ?? "";
		this.deliveryPostCode = data?.deliveryPostCode ?? "";
		this.deliveryCountry = data?.deliveryCountry ?? "";
		this.deliveryDate = data?.deliveryDate ?? Timestamp.now();
		this.deliveryType = data?.deliveryType ?? OrderDeliveryType.Eat_Inside;

		this.tableId = data?.tableId ?? "";
	}

	deliveryWeekday(): null | number {
		return this.deliveryDate?.toDate().getDay() ?? null;
	}
}
