import React from "react";
import { IconButton, TooltipHost } from "@fluentui/react";
import { IIconButtonProps } from "interfaces/index";

const iconProps = {
	iconName: "Cancel"
};

/**
 * Re-usable Close icon button component
 *
 * @param param0
 * @returns
 */
export function CloseIconButton({
	onClick,
	className,
	title,
	tooltip = "Close", // TODO Review text ref
	...otherProps
}: IIconButtonProps) {
	return (
		<TooltipHost
			title={tooltip}
			aria-label={tooltip}
			styles={{ root: { display: "inline-block" } }}
		>
			<IconButton
				iconProps={iconProps}
				onClick={onClick}
				className={`button icon ${className ?? ""}`}
				title={title ?? tooltip}
				aria-label={tooltip}
				{...otherProps}
			/>
		</TooltipHost>
	);
}
