import React, { ComponentType, Suspense, lazy } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";

import { Routes } from "./Routes";
import { Loader } from "components/shared/loader";
import { AppAuthentication } from "components/shared/layer/AppAuthentication";

// Selectors and Services
import { UserSelectors } from "redux/selectors";

/**
 * Contract for the paths of the page components.
 * Keeps a structure type-enforced.
 */
interface IComponentPaths {
	BeeAIChatPage: string;
	CategoriesPage: string;
	BrandsPage: string;
	ClientsPage: string;
	ProductsPage: string;
	ProductStockPage: string;
	ProductStockEditForm: string;
	SuppliersPage: string;
	LandingMailsPage: string;
	OrderInternalPage: string;
	OrderProductionPage: string;
	OrderProductionCarouselPage: string;
	OrderManagementPage: string;
	HomePage: string;
	LoginPage: string;
	LostPasswordPage: string;
	LandingPage: string;
	Error404Page: string;
	SignUpPage: string;
	OnBoardingPage: string;
	ClientOrderList: string;
	ClientOrder: string;
	ClientTables: string;
	ExternalOrder: string;
	StoreHome: string;
	StoreItemView: string;
	StockMovementsListPage: string;
	CompanyLinksList: string;
}

/**
 * The paths to the page components.
 * Used for Lazy loading the components.
 * Should be Updated whenever routes are changed/added.
 */
const ComponentPaths: IComponentPaths = {
	BeeAIChatPage: "chat/OpenAIChatPage",
	CategoriesPage: "categories/CategoriesPage",
	BrandsPage: "brands/BrandsPage",
	ClientsPage: "clients/ClientsPage",
	ProductsPage: "products/ProductsPage",
	ProductStockPage: "productStock/ProductStockPage",
	ProductStockEditForm: "productStock/ProductStockEditForm",
	SuppliersPage: "suppliers/SuppliersPage",
	LandingMailsPage: "landingMails/LandingMailsPage",
	OrderInternalPage: "order/internal/OrderInternalPage",
	OrderProductionPage: "order/internal/OrderProductionPage",
	OrderProductionCarouselPage: "order/internal/OrderProductionCarouselPage",
	OrderManagementPage: "order/management/OrderManagementPage",
	HomePage: "home/HomePage",
	LoginPage: "login/LoginPage",
	LostPasswordPage: "login/lostPassword/LostPasswordPage",
	LandingPage: "landing/LandingPage",
	Error404Page: "error/Error404Page",
	SignUpPage: "signUp/SignUpPage",
	OnBoardingPage: "onBoarding/OnBoardingPage",
	ClientOrderList: "order/list/OrderListPage",
	ClientOrder: "order/internal/OrderInternalPage", // TODO: Change file ref to match properly ClientOrderPage
	ClientTables: "clientTables/ClientTablesPage",
	ExternalOrder: "order/external/OrderExternalPage",
	StoreHome: "store/StorePage",
	StoreItemView: "store/StoreItemViewPage",
	StockMovementsListPage: "stockMovement/StockMovementPage",
	CompanyLinksList: "companyLinks/CompanyLinksPage"
};

/**
 * Code sample from
 * https://codesandbox.io/p/sandbox/gracious-chaum-mjv9f5?file=%2Fsrc%2FApp.js%3A45%2C15
 */
const LazyLoad = (componentPath: string) => {
	const loadFn: () => Promise<{ default: ComponentType<any> }> = () =>
		import(`components/page/${componentPath}`);
	const Comp = lazy(loadFn);

	return (
		<Suspense fallback={<Loader open />}>
			<Comp />
		</Suspense>
	);
};

/**
 * Wraps the passed pages with the authentication layer.
 *
 * @param pages The pages to wrap
 * @returns The wrapped pages as a collection of RouteObject
 */
function AuthenticationLayer(pages: RouteObject[]): RouteObject[] {
	return pages.map((route) => {
		return {
			...route,
			element: <AppAuthentication>{route.element}</AppAuthentication>
		};
	});
}

/**
 * The routes for the list pages.
 */
const ListRoutes = AuthenticationLayer([
	{
		path: Routes.CategoriesList,
		element: LazyLoad(ComponentPaths.CategoriesPage)
	},
	{
		path: Routes.BrandsList,
		element: LazyLoad(ComponentPaths.BrandsPage)
	},
	{
		path: Routes.ProductsList,
		element: LazyLoad(ComponentPaths.ProductsPage)
	},
	{
		path: Routes.ProductStockList,
		element: LazyLoad(ComponentPaths.ProductStockPage),
		children: [
			{
				path: Routes.ProductStockEdit(),
				element: LazyLoad(ComponentPaths.ProductStockEditForm)
			}
		]
	},
	{
		path: Routes.StockMovementsList,
		element: LazyLoad(ComponentPaths.StockMovementsListPage)
	},
	{
		path: Routes.ClientsList,
		element: LazyLoad(ComponentPaths.ClientsPage)
	},
	{
		path: Routes.SuppliersList,
		element: LazyLoad(ComponentPaths.SuppliersPage)
	},
	{
		path: Routes.LandingMails,
		element: LazyLoad(ComponentPaths.LandingMailsPage)
	},
	{
		path: Routes.SellOrderForm,
		element: LazyLoad(ComponentPaths.OrderInternalPage)
	},
	{
		path: Routes.OrderManagement,
		element: LazyLoad(ComponentPaths.OrderManagementPage)
	},
	{
		path: Routes.ClientOrderList,
		element: LazyLoad(ComponentPaths.ClientOrderList)
	},
	{
		path: Routes.ClientOrder(),
		element: LazyLoad(ComponentPaths.ClientOrder)
	},
	{
		path: Routes.OrderProductionPage,
		element: LazyLoad(ComponentPaths.OrderProductionPage)
	},
	{
		path: Routes.OrderProductionCarouselPage,
		element: LazyLoad(ComponentPaths.OrderProductionCarouselPage)
	},
	{
		path: Routes.ExternalOrderForm,
		element: LazyLoad(ComponentPaths.ExternalOrder)
	},
	{
		path: Routes.BeeAIChat(),
		element: LazyLoad(ComponentPaths.BeeAIChatPage)
	},
	// Store Routes
	{
		path: Routes.StoreHome(),
		element: LazyLoad(ComponentPaths.StoreHome)
	},
	{
		path: Routes.StoreItemView(),
		element: LazyLoad(ComponentPaths.StoreItemView)
	},
	{
		path: Routes.ClientTables,
		element: LazyLoad(ComponentPaths.ClientTables)
	},
	{
		path: Routes.CompanyLinkList,
		element: LazyLoad(ComponentPaths.CompanyLinksList)
	}
]);

/**
 * The [Authenticated] Router object, which contains the routes and the elements to render.
 */
export const RouterConfig: RouteObject[] = [
	...ListRoutes,
	...AuthenticationLayer([
		{
			path: Routes.Home,
			element: LazyLoad(ComponentPaths.HomePage)
		}
	]),
	{
		path: Routes.Landing,
		element: LazyLoad(ComponentPaths.LandingPage)
	},
	{
		path: Routes.Error404,
		element: LazyLoad(ComponentPaths.Error404Page)
	},
	{
		path: Routes.All,
		element: <Navigate to={Routes.Home} replace />
	}
];

/**
 * The [Anonymous] Router object, which contains the routes and the elements to render.
 */
export const RouterAnon: RouteObject[] = [
	{
		path: Routes.Landing,
		element: LazyLoad(ComponentPaths.LandingPage)
	},
	{
		path: Routes.Login,
		element: LazyLoad(ComponentPaths.LoginPage)
	},
	{
		path: Routes.Error404,
		element: LazyLoad(ComponentPaths.Error404Page)
	},
	{
		path: Routes.LoginPasswdRecovery,
		element: LazyLoad(ComponentPaths.LostPasswordPage)
	},
	{
		path: Routes.SignUp,
		element: LazyLoad(ComponentPaths.SignUpPage)
	},
	{
		path: Routes.OnBoarding,
		element: LazyLoad(ComponentPaths.OnBoardingPage)
	},
	{
		path: Routes.All,
		element: <Navigate to={Routes.Landing} />
	}
];

/**
 * Renders pages based on the passed props.routePath.
 *
 * @param {*} param0 The Properties of the component
 * @returns JSX.Element
 */
function AppRouter(): JSX.Element {
	const isAuth = useSelector(UserSelectors.selectAuthenticated);
	const routes = useRoutes(isAuth ? RouterConfig : RouterAnon);

	return routes;
}

export { AppRouter };
