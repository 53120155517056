import React from "react";
import { CopyIcon } from "components/shared/icon";
import { IconSize } from "components/shared/icon/shared/interfaces";

/**
 * Copy content wrapper properties interface.
 */
export interface ICopyContentWrapperProps {
	content: string;
	tooltip?: string;
}

/**
 * Copy content wrapper component.
 * It adds a "Copy content" icon at the end of the passed content by prop.
 *
 * @returns JSX.Element
 */
export const CopyContentWrapper: React.FC<ICopyContentWrapperProps> = ({
	content,
	tooltip = ""
}) => {
	/**
	 * Controls the content copying functionality
	 */
	const copyToClipboard = () => {
		navigator.clipboard.writeText(content.toString());
	};

	return content ? (
		<>
			<span onClick={copyToClipboard} title={tooltip}>
				{content}
			</span>
			&nbsp;
			<CopyIcon
				tooltip={tooltip}
				onClick={copyToClipboard}
				size={IconSize.SM}
			/>
		</>
	) : (
		<span className="empty">-</span>
	);
};
