import { createSelector } from "@reduxjs/toolkit";
import { IProduct } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Product.
 */
export const ProductSelectors = {
	getProducts: (state: RootState) => state.products.list,
	getProductByIds: createSelector(
		(state: RootState, itemIds: string[]) => itemIds,
		(state: RootState) => state.products.list,
		(itemIds, products: IProduct[]) =>
			products?.filter((product) => itemIds.includes(product.id)) ?? []
	),
	getProductById: createSelector(
		(state: RootState, itemId: string) => itemId,
		(state: RootState) => state.products.list,
		(itemId, products: IProduct[]) =>
			products.find((product) => product.id === itemId)
	),
	getProductsLoading: (state: RootState) => state.products.loading,
	getProductsQueried: (state: RootState) => state.products.queried,
	getIsEditing: (state: RootState) => state.products.editing,
	getProductsEditing: (state: RootState) => state.products.editingList,
	getProductsInStockIds: (state: RootState): string[] =>
		Object.keys(state.products.inStockIds),
	getProductInStock: createSelector(
		(state: RootState) => Object.keys(state.products.inStockIds),
		(_: RootState, productId: string) => productId,
		(inStockProductIds, productId): boolean =>
			inStockProductIds.includes(productId)
	),
	getProductStockEntryIds: createSelector(
		(state: RootState) => state.products.inStockIds,
		// (state: RootState) => state.products.inStockIds,
		(state: RootState) => Object.keys(state.products.inStockIds),
		(_: RootState, productId: string) => productId,
		(state, inStockProductIds, productId): string[] =>
			inStockProductIds.includes(productId) ? state[productId] : []
	)
	// getProductsError: (state: RootState) => state.products.error
};
