import React from "react";
import { ActionButton, IconButton, TooltipHost } from "@fluentui/react";
import { IIconButtonProps } from "interfaces/index";

const iconProps = {
	iconName: "Recent"
};

/**
 * Re-usable Recent icon button component
 *
 * @param param0
 * @returns
 */
export function RecentIconButton({
	onClick,
	className,
	title,
	tooltip = "Recent", // TODO Review text ref
	text = undefined,
	...otherProps
}: IIconButtonProps) {
	return (
		<TooltipHost
			title={tooltip}
			aria-label={tooltip}
			styles={{ root: { display: "inline-block" } }}
		>
			{undefined !== text ? (
				<ActionButton
					iconProps={iconProps}
					onClick={onClick}
					className={`button icon confirm ${className ?? ""}`}
					title={title ?? tooltip}
					aria-label={tooltip}
					text={text ?? undefined}
					{...otherProps}
				/>
			) : (
				<IconButton
					iconProps={iconProps}
					onClick={onClick}
					className={`button icon confirm ${className ?? ""}`}
					title={title ?? tooltip}
					aria-label={tooltip}
					{...otherProps}
				/>
			)}
		</TooltipHost>
	);
}
