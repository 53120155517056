import { configureStore } from "@reduxjs/toolkit";
import { RootReducer } from "./reducers/rootReducer";
import type { RootState } from "./reducers/rootReducer";
import { persistReducer, persistStore } from "redux-persist";
import { ReduxSlices } from "./slices";
import thunk from "redux-thunk";
import localStorage from "redux-persist/es/storage";

/**
 * Redux Persist configuration.
 */
const appPersistConfig = {
	key: "root",
	storage: localStorage,
	whitelist: [ReduxSlices.UserProfile]
};

const persistedReducer = persistReducer(appPersistConfig, RootReducer);

/**
 * Store. Configured based on the set of reducers specified in RootReducer.
 */
const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== "production",
	middleware: [thunk]
	// enhancers: (defaultEnhancers) => [...defaultEnhancers]
});

const appPersistor = persistStore(store);

export default store;

export { RootState, appPersistor, appPersistConfig };
