import { IStoreSlice } from "interfaces/index";
import { useSelector } from "react-redux";
import { StoreSelectors } from "redux/selectors";

/**
 * Hook to get the Store data from the Redux store.
 *
 * @returns {IStoreSlice} Store data
 */
export const useStoreData = (): IStoreSlice => {
	const store = useSelector(StoreSelectors.getStore);

	return store;
};
