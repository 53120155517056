import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxSlices } from "redux/slices";
import {
	ICategory,
	ICompany,
	storeInitialState as initialState,
	IProduct
} from "models";
import { ICartProduct, IStore } from "interfaces";

/**
 * Products Redux slice.
 */
export const storeSlice = createSlice({
	name: ReduxSlices.Store,
	initialState,
	reducers: {
		setStore: (state, action: PayloadAction<IStore>) => {
			state.info = action.payload;
		},
		setCompanyLoading: (state, action: PayloadAction<boolean>) => {
			state.loading.company = action.payload;
		},
		setProductsLoading: (state, action: PayloadAction<boolean>) => {
			state.loading.products = action.payload;
		},
		setCategoriesLoading: (state, action: PayloadAction<boolean>) => {
			state.loading.categories = action.payload;
		},
		setProductsLoaded: (state, action: PayloadAction<boolean>) => {
			state.loaded.products = action.payload;
		},
		setCategoriesLoaded: (state, action: PayloadAction<boolean>) => {
			state.loaded.categories = action.payload;
		},
		setProducts: (state, action: PayloadAction<IProduct[]>) => {
			state.products = action.payload;
		},
		setCategories: (state, action: PayloadAction<ICategory[]>) => {
			state.categories = action.payload;
		},
		setProductImages: (
			state,
			action: PayloadAction<{ productId: string; imagePath: string }[]>
		) => {
			state.productImages = action.payload;
		},
		setSelectedCategory: (
			state,
			action: PayloadAction<null | ICategory>
		) => {
			state.selectedCategory = action.payload;
		},
		setSelectedProduct: (state, action: PayloadAction<null | IProduct>) => {
			state.selectedProduct = action.payload;
		},
		setProductsDisplayCount: (state, action: PayloadAction<number>) => {
			state.displayCount.products = action.payload;
		},
		setCategoriesDisplayCount: (state, action: PayloadAction<number>) => {
			state.displayCount.categories = action.payload;
		},
		setExpandedCategories: (state, action: PayloadAction<string[]>) => {
			state.expandedCategories = action.payload;
		},
		setFoundCompany: (state, action: PayloadAction<null | ICompany>) => {
			state.company = action.payload;
		},
		setCartOpen: (state, action: PayloadAction<boolean>) => {
			state.cart.open = action.payload;
		},
		setCartItems: (state, action: PayloadAction<ICartProduct[]>) => {
			state.cart.items = action.payload;
		},
		setCartTotal: (state, action: PayloadAction<number>) => {
			state.cart.total = action.payload;
		},
		resetProducts: (state) => {
			state.products = initialState.products;
		},
		resetCategories: (state) => {
			state.categories = initialState.categories;
		},
		resetProductImages: (state) => {
			state.productImages = initialState.productImages;
		},
		resetCart: (state) => {
			state.cart = initialState.cart;
		}
	}
});

export const { reducer, actions } = storeSlice;

export default reducer;
