import { appStateService } from "App";
import { IAddressMetadata } from "interfaces/index";
import { useCallback, useState } from "react";
import { ICEPResponse } from "services/postalCode/ViaCEPService";

/**
 * Contract for the usePostCodeSearch hook.
 */
export interface IUsePostCodeSearchHook {
	onSearch: (
		e: any,
		onResolve?:
			| undefined
			| ((result: IAddressMetadata) => void | Promise<void>)
	) => void;
	data: null | IAddressMetadata;
}

/**
 * Enables the use of the postal code search feature.
 *
 * @returns a structure containing the search function and the found address data.
 */
export const usePostCodeSearch = () => {
	const [foundAddress, setFoundAddress] = useState<null | IAddressMetadata>(
		null
	);

	/**
	 * Searches for the client address based on the postal code.
	 *
	 * @param e The event that triggered the search.
	 */
	const onSearchClientAddress = useCallback(
		async (
			e: any,
			onResolve?:
				| undefined
				| ((result: IAddressMetadata) => void | Promise<void>)
		) => {
			const cepNumbers = e.target.value.replace(/\D/gi, "");

			setFoundAddress({
				...foundAddress,
				postCode: cepNumbers
			});

			if (cepNumbers.length < 8) return;

			// Queries the address API to get the address info
			await appStateService.viaCEP
				.getCEP(cepNumbers)
				.then((address: ICEPResponse) => {
					const addressData: IAddressMetadata = {
						postCode: address.cep,
						address: address.logradouro,
						city: address.localidade,
						borough: address.bairro,
						state: address.uf,
						country: "Brasil"
					};

					setFoundAddress({
						...foundAddress,
						...addressData
					});

					if (typeof onResolve === "function") onResolve(addressData);
				});
		},
		[foundAddress]
	);

	return {
		onSearch: onSearchClientAddress,
		data: foundAddress,
		setData: setFoundAddress
	};
};
