import React from "react";
import { Button, ButtonVariant } from "components/shared/button";

import "../base/FormBase.scss";

/**
 * FormControls props contract interface.
 */
export interface IFormControlsProps {
	submitDisabled?: undefined | boolean;
	submitText?: string;
	cancelText?: string;
	onCancel: () => void;
	onSubmit: () => void;
}

/**
 * Standard FormControls component.
 *
 * @param props
 * @returns
 */
export const FormControls: React.FC<IFormControlsProps> = (props) => {
	const texts = {
		button: {
			cancel: props.cancelText ?? "Cancelar",
			confirm: props.submitText ?? "Confirmar"
		}
	};

	return (
		<div className="form-controls">
			<Button
				variant={ButtonVariant.Secondary}
				text={texts.button.cancel}
				onClick={props.onCancel}
			/>
			<Button
				text={texts.button.confirm}
				disabled={props?.submitDisabled ?? false}
				onClick={props.onSubmit}
			/>
		</div>
	);
};
