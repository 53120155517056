import { IClientTable } from "models/index";
import { actions } from "redux/reducers/clientTables/reducer";
import { CompanySegment } from "services/companies/CompanySegment";

/**
 * Class for managing the basics of the user's Company Client use Table data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanyClientTableService extends CompanySegment<IClientTable> {
	constructor(companyId: string) {
		super(companyId, "clientTables", actions.setList, actions.setQueried);
	}
}

export { CompanyClientTableService };
