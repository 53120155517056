// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
// import {
// 	App,
// 	initializeApp as initializeFirebaseAdmin
// } from "firebase-admin/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { Firestore, getFirestore } from "firebase/firestore";
import { FirebaseStorage, getStorage } from "firebase/storage";
// import { Firestore, getFirestore } from "firebase-admin/firestore";
import {
	Auth,
	// browserLocalPersistence,
	// initializeAuth
	getAuth
	// getAuth,
} from "firebase/auth";
import "firebase/firestore";
import "firebase/app-check";
import {
	AppCheck,
	initializeAppCheck,
	ReCaptchaV3Provider
} from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

/**
 * Loads the Firebase configuration file,
 * based on the environment selection.
 */
const configFile = require(process.env.NODE_ENV === "development"
	? "./firebaseConfig.development.json"
	: "./firebaseConfig.release.json");

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_KEY ?? configFile.apiKey,
	authDomain: configFile.authDomain,
	projectId: configFile.projectId,
	storageBucket: configFile.storageBucket,
	messagingSenderId: configFile.messagingSenderId,
	appId: configFile.appId,
	measurementId: configFile.measurementId
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const adminApp = initializeFirebaseAdmin(firebaseConfig);
const auth = getAuth();
// initializeAuth(app, {
// 	persistence: browserLocalPersistence,
// 	popupRedirectResolver: null
// });
const analytics = getAnalytics();
const firestore = getFirestore();
const fbStorage = getStorage(app);
const appCheck = initializeAppCheck(app, {
	provider: new ReCaptchaV3Provider(process.env.REACT_APP_APP_CHECK_KEY)
});

/**
 * Contract for the Firebase Service fixture.
 */
export interface IFirebaseService {
	// admin: App;
	app: FirebaseApp;
	auth: Auth;
	analytics: Analytics;
	firestore: Firestore;
	storage: FirebaseStorage;
	appCheck: AppCheck;
}

/**
 * Firebase Service fixture implementation class.
 *
 * @see https://en.wikipedia.org/wiki/Singleton_pattern
 * @see https://en.wikipedia.org/wiki/Fixture_(software)
 * @see https://en.wikipedia.org/wiki/Service_locator_pattern
 * @see https://en.wikipedia.org/wiki/Service_provider_interface
 * @see https://firebase.google.com/docs/web/setup#available-libraries
 */
export class FirebaseService implements IFirebaseService {
	// admin: App;
	app: FirebaseApp;
	auth: Auth;
	analytics: Analytics;
	firestore: Firestore;
	storage: FirebaseStorage;
	appCheck: AppCheck;

	constructor() {
		// this.admin = adminApp;
		this.app = app;
		this.auth = auth;
		this.analytics = analytics;
		this.firestore = firestore;
		this.storage = fbStorage;
		this.appCheck = appCheck;
	}
}

/**
 * Firebase Service fixture instance.
 * This is a singleton of the Firebase Services within the App.
 */
export const firebaseService = new FirebaseService();
