import React, { useMemo } from "react";
import { IconButton, TooltipHost } from "@fluentui/react";
import { IIconButtonProps } from "interfaces/index";
import { useTranslateFn } from "hooks/i18n";

const iconProps = {
	iconName: "Back"
};

/**
 * Re-usable Back icon button component
 *
 * @param param0
 * @returns
 */
export function BackIconButton({
	onClick,
	className,
	title,
	tooltip = null, // TODO Review text ref
	...otherProps
}: IIconButtonProps) {
	const translate = useTranslateFn();
	const strings = {
		tooltip: translate("global.button.backToHome")
	};
	const resolvedTooltip = useMemo(
		() => tooltip ?? strings.tooltip,
		[tooltip, strings.tooltip]
	);

	return (
		<TooltipHost
			title={resolvedTooltip}
			aria-label={resolvedTooltip}
			styles={{ root: { display: "inline-block" } }}
		>
			<IconButton
				iconProps={iconProps}
				onClick={onClick}
				className={`button icon back ${className ?? ""}`}
				title={title ?? resolvedTooltip}
				aria-label={resolvedTooltip}
				{...otherProps}
			/>
		</TooltipHost>
	);
}
