import React from "react";

/**
 * Enum for Button Variants
 */
enum ButtonVariant {
	Primary = "primary",
	Secondary = "secondary",
	Tertiary = "tertiary"
}

/**
 * Button props contract interface.
 * @module Button
 */
interface IButtonProps {
	text: string;
	type?: "button" | "submit" | "reset" | undefined;
	children?: undefined | JSX.Element | (() => JSX.Element);
	variant?: undefined | ButtonVariant;
	onClick?:
		| undefined
		| (() => void)
		| ((event: React.MouseEvent<HTMLButtonElement>) => void);
	disabled?: undefined | boolean;
	noMargin?: undefined | boolean;
	upper?: undefined | boolean;
	bold?: undefined | boolean;
	id?: undefined | string;
	className?: undefined | string;
	title?: undefined | string;
	alt?: undefined | string;
	tooltip?: undefined | string;
}

/**
 * Base Button Component,
 * can handle different variants and behave differently depending on props configuration.
 *
 * @param props
 * @returns
 */
const Button: React.FC<IButtonProps> = (props) => {
	const content = !!props.text
		? props.text
		: typeof props.children === "function"
		? props.children()
		: props.children;
	const className = !!props.className ? props.className : "";

	async function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
		event.preventDefault();
		if (typeof props.onClick === "function") props.onClick(event);
	}

	return (
		<button
			disabled={props.disabled ?? undefined}
			type={!!props.type ? props.type : "button"}
			onClick={handleClick}
			className={`btn btn-${props.variant ?? ButtonVariant.Primary} ${
				props?.noMargin ? "btn-margin-0" : ""
			} ${props?.bold ? "bold-text" : ""} ${
				props?.upper ? "btn-upper" : ""
			} ${className}`}
			title={props.title ?? undefined}
			id={props.id ?? undefined}
		>
			{content}
		</button>
	);
};

export { Button, IButtonProps, ButtonVariant };
