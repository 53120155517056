import { IDropdownOption } from "@fluentui/react";
import { createSelector } from "@reduxjs/toolkit";

import { IOption } from "components/shared/dropdown";
import { ChatInstance } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: AIChat.
 */
export const AIChatSelectors = {
	getAIChats: (state: RootState) => state.aiChats.list,
	getAIChatsDropdownList: createSelector(
		(state: RootState): IDropdownOption<IOption>[] =>
			state.aiChats.list?.map((aiChat: ChatInstance) => ({
				text: `${aiChat.title}`,
				key: aiChat.id
			})),
		(list: IDropdownOption<IOption>[]) => list
	),
	getChatById: createSelector(
		(state: RootState) => state,
		(_, id: string) => id,
		(state: RootState, id: string) =>
			state.aiChats.list?.find((aiChat: ChatInstance) => aiChat.id === id)
	),
	getAIChatsLoading: (state: RootState) => state.aiChats.loading,
	getAIChatsQueried: (state: RootState) => state.aiChats.queried,
	getIsEditing: (state: RootState) => state.aiChats.editing,
	getAIChatsEditing: (state: RootState) => state.aiChats.editingList,
	getAIActiveChat: (state: RootState): ChatInstance =>
		state.aiChats.activeChat
	// getAIChatsError: (state: RootState) => state.aiChats.error
};
