import { ClientOrder } from "models/order/client/ClientOrder";
import { IClientOrder, IProduct } from "models";

/**
 * Product Redux slice interface.
 */
interface IClientOrderFormSlice {
	order: IClientOrder;
	addingProduct: boolean;
	productsList: IProduct[];
	loading: boolean;
	queried: boolean;
	productSearchText: string;
	// error: string;
}

/**
 * User profile initial state.
 */
const orderFormInitialState: IClientOrderFormSlice = {
	order: new ClientOrder(),
	addingProduct: true,
	productsList: [],
	loading: false,
	queried: false,
	productSearchText: ""
};

export { IClientOrderFormSlice, orderFormInitialState };
