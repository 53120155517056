import React, { useMemo } from "react";

/**
 * The ShortId field renderer will respect the maximum
 * length expressed by this constant.
 */
const SHORT_ID_LENGTH = 8;
/**
 * If true, the short ID will be in uppercase.
 */
const SHORT_ID_UPPER = true;

/**
 * Calculates the short id from a given string.
 * Checks the length before cutting something shorter.
 *
 * @param id The ID, minimal length according to config.
 * @param length [Optional] The length of the short ID. Default is 8.
 * @param useLower [Optional] If true, the short ID will be in lowercase. Default is false.
 * @returns The shortened ID.
 */
export function getShortId(
	id: string,
	length: number = SHORT_ID_LENGTH,
	useUpper: boolean = SHORT_ID_UPPER
): string {
	let shortId = id?.toString().substring(0, length);

	return useUpper ? shortId.toLocaleUpperCase() : shortId;
}

/**
 * SemiId Component.
 * Renders a shortened version of a given ID.
 *
 * @param id The ID to be shortened.
 * @returns The shortened ID within a HTML <span> component.
 */
export const ShortId: React.FC<{ id: string }> = ({ id }) => {
	const shortId = useMemo(() => getShortId(id), [id]);

	return <span>{shortId}</span>;
};
