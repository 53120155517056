import { AIInternalPreTrainedDataModelNames } from "services/openai/models/AIModels";

/**
 * Models to be used in the fine-tuning of the models.
 */
const TrainingDataModels = {
	productAverageMarketPrice:
		AIInternalPreTrainedDataModelNames.productAverageMarketPrice, //"product-average-market-price",
	brandDescription: AIInternalPreTrainedDataModelNames.brandDescription, // "brand-description",
	productDescription: AIInternalPreTrainedDataModelNames.productDescription, // "product-description",
	categoryDescription: AIInternalPreTrainedDataModelNames.categoryDescription, // "category-description",
	productImageInterpreter:
		AIInternalPreTrainedDataModelNames.productImageInterpreter, // "product-image-interpreter"
	assumptions: "assumptions"
};

/**
 * Data to be used in the fine-tuning of the models.
 * Each model has its own data, as string arrays. These strings are example of both prompts and expected outputs.
 */
const TrainingData = {
	[TrainingDataModels.assumptions]: [
		"Você se chama Bee AI. Você é uma assistente muito útil em um back-office de Comércio Digital. ",
		"O usuário que está se comunicando com você se chama ${userName}.",
		"O nome da empresa a qual você está atuando neste momento é ${companyName}"
	],
	[TrainingDataModels.productAverageMarketPrice]: [
		`Você agora está buscando preços médios de produtos que estão no mercado. ` +
			`Sendo assim, ajude o usuário a encontrar tais informações com a pesquisa na internet. ` +
			`O formato do pedido vai ser: "Produto Marca Peso/conteúdo". ` +
			`Assuma que essas tags podem variar de posição na pergunta. ` +
			`Por exemplo, "Nescafé Solúvel 100g" é um pedido, ` +
			`onde "Nescafé" é a marca, "Solúvel" é o aspecto do produto e "100g" se trata do peso/conteúdo na embalagem. ` +
			`Nem sempre a ordem do nome do produto segue esta lógica, as vezes está como "Cerveja Heineken 355ml". ` +
			`Neste caso, o nome do produto é "Heineken", o aspecto é "Cerveja" e o peso/conteúdo é "355ml". ` +
			`Vou armazenar em minha base de dados, portanto, ` +
			`envie somente o range de preços. ` +
			`Ao processar os dados com pesquisa na internet, estou esperando a resposta no formato ` +
			`Moeda Preço Mínimo - Moeda Preço Máximo. ` +
			`Pergunta: Nescafé Solúvel 100g ` +
			`Resposta: R$ 10,00 - R$ 24,00. `,
		"Pergunta: Maionese Hellmann's 500g",
		"Resposta: R$ 7,65 - R$ 24,21",
		"Pergunta: Cerveja Heineken 355ml",
		"Resposta: R$ 4,51 - R$ 12,11",
		"Pergunta: Camiseta Nike Branca Algodão",
		"Resposta: R$ 50,00 - R$ 150,00",
		"Pergunta: Bermuda Adidas Preta Poliéster",
		"Resposta: R$ 80,00 - R$ 200,00"
	],
	[TrainingDataModels.brandDescription]: [
		`O usuário irá perguntar sobre Marcas conhecidas. ` +
			`Você, como assistente, vai responder objetivamente à pergunta. ` +
			`Vou armazenar em minha base de dados. Envie somente a descrição com uso de poucos tokens (até 10 tokens). `,
		`Por exemplo: ` +
			`Pergunta: Você poderia me descrever brevemente a marca "Nescafé"? ` +
			`Resposta: Marca de café solúvel da Nestlé, fundada em 1938. Fabricado com grãos de café torrados e moídos. <Outra informação>`,
		"Pergunta: Heineken",
		"Resposta: Marca de cerveja holandesa, fundada em 1864. Fabricada com água, malte, lúpulo e levedura.",
		"Pergunta: Nike",
		"Resposta: Marca de roupas esportivas, fundada em 1964. Fabricada com algodão, poliéster e elastano.",
		"Pergunta: Hellmann's",
		"Resposta: Marca de maionese, fundada em 1905. Fabricada com ovos, óleo vegetal e vinagre.",
		"Pergunta: Nestlé",
		"Resposta: Marca de alimentos, fundada em 1866. Fabrica produtos a base de leite, cacau e açúcar.",
		"Pergunta: MC Donald's",
		"Resposta: Marca de fast food, fundada em 1940. Fabrica lanches, batatas fritas e refrigerantes."
	]
};

export { TrainingDataModels, TrainingData };
