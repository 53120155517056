import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxSlices } from "redux/slices";
import {
	companyLinksInitialState as initialState,
	ILinkInfo
} from "models/link";
import { ISetEditingAction } from "interfaces/actions";

/**
 * LinkInfos Redux slice.
 */
export const companyLinksSlice = createSlice({
	name: ReduxSlices.CompanyLinks,
	initialState,
	reducers: {
		setList: (state, action: PayloadAction<ILinkInfo[]>) => {
			state.list = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setQueried: (state, action: PayloadAction<boolean>) => {
			state.queried = action.payload;
			state.loading = false;
		},
		setEditing: (
			state,
			action: PayloadAction<ISetEditingAction<ILinkInfo>>
		) => {
			state.editing = action.payload.editing;
			state.editingList = action.payload.items;
		},
		setEditByID: (state, action: PayloadAction<ILinkInfo>) => {
			const index = state.list.findIndex(
				(item) => item.id === action.payload.id
			);
			if (index >= 0) {
				const newList = [...state.list];

				newList[index] = action.payload;

				state.list = newList;
			}
		},
		setEditingList: (state, action: PayloadAction<ILinkInfo[]>) => {
			state.editingList = action.payload;
		},
		resetEditing: (state) => {
			state.editing = false;
			state.editingList = [];
		},
		reset: (state) => {
			state.editing = false;
			state.editingList = [];
			state.list = [];
			state.loading = false;
			state.queried = false;
		},
		addLink: (state, action: PayloadAction<ILinkInfo>) => {
			state.list.push(action.payload);
		},
		updateLink: (state, action: PayloadAction<ILinkInfo>) => {
			const index = state.list.findIndex(
				(link) => link.id === action.payload.id
			);
			if (index !== -1) {
				state.list[index] = action.payload;
			}
		},
		removeLink: (state, action: PayloadAction<string>) => {
			state.list = state.list.filter(
				(link) => link.id !== action.payload
			);
		}
		// setError: (state, action: PayloadAction<string>) => {
		// 	state.error = action.payload;
		// }
	}
});

export const { reducer, actions } = companyLinksSlice;

export default reducer;

// TODO: Selectors, split file
