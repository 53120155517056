import { IStockMovement } from "models/index";
import { CompanySegment } from "services/companies/CompanySegment";
import { actions } from "redux/reducers/stock/movement/reducer";

/**
 * Stores and provides info to the company's Stock movement,
 * providing a tracker to the modifications occurred to the company's inventory.
 */
export class CompanyStockMovementService extends CompanySegment<IStockMovement> {
	constructor(companyId: string) {
		super(companyId, "stockMovement", actions.setList, actions.setQueried);
	}


}
