// Wrapper component using fluent UI icon

// Path: src/components/shared/icon.tsx

import React from "react";

import { Icon as FluentIcon, TooltipHost } from "@fluentui/react";

import { IIconProps, IconSize } from "./shared/interfaces";

import "./shared/Icon.scss";

export enum IconColor {
	green = "green",
	red = "red",
	blue = "blue",
	gray = "gray",
	primary = "primary",
	secondary = "secondary"
}

/**
 * Re-usable Icon component
 *
 * @param props
 * @returns
 */
function Icon(props: IIconProps): JSX.Element {
	const tooltip =
		props?.tooltip && props.tooltip !== "" ? props.tooltip : undefined;
	const sizeClassName =
		props?.size === IconSize.SM
			? "icon-sm"
			: props?.size === IconSize.MD
			? "icon-md"
			: "icon-lg";
	const colorBasedClassName = props?.color ? `icon-${props.color}` : "";
	const className = `icon ${sizeClassName} ${
		props.className ?? ""
	} ${colorBasedClassName}`;

	return (
		<TooltipHost
			title={tooltip}
			aria-label={tooltip}
			styles={{ root: { display: "inline-block" } }}
			// calloutProps={{
			// 	title: tooltip
			// }}
			// closeDelay={200}
		>
			<FluentIcon
				className={className}
				iconName={props.name}
				onClick={props?.onClick}
				aria-label={tooltip}
				title={tooltip}
				{...props}
			/>
		</TooltipHost>
	);
}

export { Icon };
