import { ILinkInfo } from "models/index";
import { actions } from "redux/reducers/links/reducer";
import { CompanySegment } from "services/companies/CompanySegment";
import { whereLike } from "services/firebase/FirebaseServiceHandler";

const _baseStoragePath = (productId: string) => `links/${productId}`;

/**
 * Class for managing the basics of the user's Company Link data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanyLinkService extends CompanySegment<ILinkInfo> {
	constructor(companyId: string) {
		super(companyId, "links", actions.setList, actions.setQueried);
	}

	async searchLinks(
		text: string,
		props: string[],
		onResolve: undefined | ((items: ILinkInfo[]) => void)
	): Promise<ILinkInfo[]> {
		const links = Promise.all(
			props.map(async (propKey) => {
				const items = await this.queryItems(whereLike(propKey, text));
				return items;
			})
		).then((items) => {
			const _items = this.unifyLists(items);

			if (onResolve) {
				onResolve(_items);
			}

			return _items;
		});

		return links;
	}

	async searchLinksByNameMail(
		text: string,
		onResolve: undefined | ((items) => void) = (items: ILinkInfo[]) => items
	): Promise<ILinkInfo[]> {
		return await this.searchLinks(text, ["name", "mail"], onResolve);
	}

	async addLink(link: ILinkInfo): Promise<boolean> {
		return this.createItem(link);
	}

	async updateLink(link: ILinkInfo): Promise<boolean> {
		return this.updateItem(link);
	}

	async removeLink(linkId: string): Promise<boolean> {
		return this.deleteItem(linkId);
	}

	async toggleFavorite(linkId: string, favorite: boolean): Promise<boolean> {
		const link = await this.getItemById(linkId);
		if (link) {
			link.favorite = favorite;
			return this.updateItem(link);
		}
		return false;
	}

	async getFavoriteLinks(): Promise<ILinkInfo[]> {
		return this.queryItems(whereLike("favorite", true));
	}
}

export { CompanyLinkService };
