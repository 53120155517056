import { createSelector } from "@reduxjs/toolkit";
import { ILinkInfo } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Company's Links.
 */
export const CompanyLinksSelectors = {
	getCompanyLinks: (state: RootState) => state.companyLinks.list,
	getCompanyLinkByIds: createSelector(
		(state: RootState) => state.companyLinks.list,
		(state: RootState, itemIds: string[]) => itemIds,
		(companyLinks: ILinkInfo[], itemIds: string[]) =>
			companyLinks.filter((companyLink) =>
				itemIds.includes(companyLink.id)
			)
	),
	getCompanyLinkById: createSelector(
		(state: RootState) => state.companyLinks.list,
		(state: RootState, itemId: string) => itemId,
		(companyLinks: ILinkInfo[], itemId: string) =>
			companyLinks.find((companyLink) => companyLink.id === itemId)
	),
	getCompanyLinksLoading: (state: RootState) => state.companyLinks.loading,
	getCompanyLinksQueried: (state: RootState) => state.companyLinks.queried,
	getIsEditing: (state: RootState) => state.companyLinks.editing,
	getCompanyLinksEditing: (state: RootState) =>
		state.companyLinks.editingList,
	getCompanyLinksList: (state: RootState) => state.companyLinks.list
	// getCompanyLinksError: (state: RootState) => state.companyLinks.error
};
